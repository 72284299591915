import { Box } from "@mui/material";
import { ErrorParagraph } from "./ErrorParagraph";

export function FromError({ errors }: { errors: string[] | string | undefined }) {
  if (!errors?.length) return null;

  if (Array.isArray(errors))
    return (
      <Box>
        {errors?.map((e: any) => (
          <ErrorParagraph key={e?.error || e} error={e?.error || e} />
        ))}
      </Box>
    );

  return <ErrorParagraph key={errors} error={errors} />;
}
