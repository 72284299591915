import { Report } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function ErrorPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        minHeight: "80vh",
      }}
    >
      <Report sx={{ fontSize: 80 }} />
      <Box>
        <Typography color="error">Erreur technique</Typography>
      </Box>
      <Link to="/">
        <Button variant="outlined">Retour</Button>
      </Link>
    </Box>
  );
}
