import { useEffect, useState } from "react";
import { Collapse, List, ListItem } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MenuType } from "profilPermissionsData";
import { useNavigate } from "react-router-dom";
import { useUser } from "utils/useGetUser";
import { useGetHabilitation } from "utils/useGetHabilitation";
import { Role } from "api/auth";

export function MenuItem({
  menu,
  withCollapse = true,
  isOpenDrawer,
  openDrawar,
  closeDrawer,
}: {
  menu: MenuType;
  withCollapse?: boolean;
  isOpenDrawer?: boolean;
  openDrawar: () => void;
  closeDrawer: () => void;
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (isOpenDrawer) setOpen(!open);
    else {
      openDrawar();
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!isOpenDrawer) setOpen(false);
  }, [isOpenDrawer]);

  const navigate = useNavigate();
  const user = useUser();
  const { habilitations } = useGetHabilitation(user?.codeSociete);
  const perimetreList = habilitations?.map((h) => h.perimetre);
  const niveauRattachementUser =
    user?.role === Role.GPSA
      ? "GPSA / AGIRA"
      : user?.role === Role.INTERMEDIAIRE
      ? "Intermédiaire"
      : "Assureur";

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.label} />
          {withCollapse && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
        </ListItemButton>
      </ListItem>
      {withCollapse && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menu.permissions
              ?.filter((permission) => {
                return (
                  user.authorities.includes(permission.codePermission) &&
                  permission.niveauRattachement.includes(niveauRattachementUser) &&
                  (perimetreList?.length === 0 ||
                    perimetreList?.filter((value) => permission.perimetre?.includes(value))
                      .length !== 0)
                );
              })
              ?.map((submenu) => (
                <ListItemButton
                  key={submenu.to}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    closeDrawer();
                    if (submenu.to) navigate(submenu.to);
                  }}
                >
                  <ListItemText primary={submenu.libellePermission} />
                </ListItemButton>
              ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
