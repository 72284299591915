
import { removeConducteurs, removeContrat, removeVehicule } from "pages/CreateReleve/api";
import {removeUser, removeTokens, getUser} from "api/auth";
import {fetchAuth} from "../../api/fetchJSON";
import {useEffect, useState} from "react";
import {baseUrl} from "../../api/baseUrl";

export function Logout() {
    const [htmlContent, setHtmlContent] = useState('');
    useEffect(() => {
        // Effectue la requête fetch pour récupérer le fichier HTML
        fetchAuth(baseUrl+"/backend/v1/logout?redirect_uri="+baseUrl+"/backend/v1/login", {
              method: "GET",
            }
        )
            .then(response => {
                removeUser();
                removeTokens();
                removeContrat();
                removeVehicule();
                removeConducteurs();
                return response.text();
            })
            .then(data => setHtmlContent(data))
            .catch(error => {
                alert('Erreur lors du fetch :' + error)
                removeUser();
                removeTokens();
                removeContrat();
                removeVehicule();
                removeConducteurs();
            });
      }, []); // Le tableau vide en second argument assure que useEffect ne s'exécute qu'une seule fois après le montage initial

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    );
}


export function logout() {
    const token = getUser().accessToken
    removeUser();
    removeTokens();
    removeContrat();
    removeVehicule();
    removeConducteurs();
    window.location.href = new URL("/backend/v1/logout?token=" + token, baseUrl).toString();
    return () => null;
}
