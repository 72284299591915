import { baseUrl } from "./baseUrl";
import { fetchJSON } from "./fetchJSON";

export type CodeSociete = {
  id: number;
  type: string;
  code: string;
  nom: string;
  actif: boolean;
};

export function getCodesSocietesActives() {
  const url = new URL("/backend/v1/codes-societes", baseUrl);
  url.searchParams.append("all", "false");
  return fetchJSON<CodeSociete[]>(url);
}

export function getAllCodesSocietes() {
  const url = new URL("/backend/v1/codes-societes", baseUrl);
  url.searchParams.append("all", "true");
  return fetchJSON<CodeSociete[]>(url);
}

export function getCodesSocietesAssureurGestionnaireGlobal(codeSocieteGestionnaireGlobal: string) {
  const url = new URL(`/backend/v1/codes-societes/gestionnaire-global`, baseUrl);
  url.searchParams.set("codeSocieteGestionnaireGlobal", codeSocieteGestionnaireGlobal);
  return fetchJSON<CodeSociete[]>(url);
}

export function getCodesSocietesGestionnaireGlobal(codeAssureur: string) {
  const url = new URL(`/backend/v1/codes-societes/gestionnaires`, baseUrl);
  url.searchParams.set("codeAssureur", codeAssureur);
  url.searchParams.set("perimetre", "G");
  return fetchJSON<CodeSociete[]>(url);
}

export function getCodesSocietesGestionnaireSinistre(codeAssureur: string) {
  const url = new URL(`/backend/v1/codes-societes/gestionnaires`, baseUrl);
  url.searchParams.set("codeAssureur", codeAssureur);
  url.searchParams.set("perimetre", "S");
  return fetchJSON<CodeSociete[]>(url);
}
