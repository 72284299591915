import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, saveUser, type User } from "api/auth";
import { useAppContext } from "pages/Layout/appContext";

export function useGetUser() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  useEffect(() => {
    function run() {
      try {
        const userRaw = localStorage.getItem("user");
        const _user: User = userRaw ? JSON.parse(userRaw) : null;
        if (!_user) return navigate("/login");
        setUser(_user);
      } catch (e) {
        console.error(e);
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
    run();
  }, [navigate]);

  return { user, setUser };
}

const rolesByCodeProfil = {
  PROF_RIE_ASSU_1: "Assureur",
  PROF_RIE_ASSU_2: "Assureur",
  PROF_RIE_ASSU_3: "Assureur",
  PROF_RIE_ASSU_4: "Assureur",
  PROF_RIE_ASSU_5: "Assureur",
  PROF_RIE_IAS_1: "Intermediaire",
  PROF_RIE_IAS_2: "Intermediaire",
  PROF_RIE_IAS_3: "Intermediaire",
  PROF_RIE_IAS_4: "Intermediaire",
  PROF_RIE_GPSA_1: "GPSA",
  PROF_RIE_GPSA_2: "GPSA",
  PROF_RIE_GPSA_3: "GPSA",
} as const;

type CodeProfil = keyof typeof rolesByCodeProfil;

export function useUser() {
  const { user } = useAppContext();

  return {
    ...user,
    role: rolesByCodeProfil[user?.codeProfil as CodeProfil],
  };
}

export function useSetUser() {
  const { user, setUser } = useAppContext();
  return (newUser: Omit<User, "token">) => {
    const role = rolesByCodeProfil[newUser?.codeProfil as CodeProfil];
    saveUser({ ...getUser(), ...newUser });
    setUser({ ...user, ...newUser });
  };
}
