import { useEffect, useState } from "react";
import { CodeSociete, getCodesSocietesActives } from "api/codesSocietes";
import { useAppContext } from "pages/Layout/appContext";

export function useGetCodesSociete() {
  const [codesSociete, setCodeSociete] = useState<CodeSociete[]>();
  const [error, setError] = useState(false);
  useEffect(() => {
    getCodesSocietesActives()
      .then(setCodeSociete)
      .catch(() => setError(true));
  }, []);

  return { codesSociete, error };
}

export function useCodesSociete() {
  const { codesSociete } = useAppContext();
  return codesSociete;
}
