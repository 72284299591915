import { RIE_Nom_Type_restriction } from "utils/regex";
import { MaskInputConform, MaskInputConformProps } from "./MaskInput";
import { iconPeople } from "utils/endAdornmentIcons";

export function NomInput({ label, field, ...props }: MaskInputConformProps) {
  return (
    <MaskInputConform
      label={label ?? "Nom"}
      mask={RIE_Nom_Type_restriction}
      field={field}
      InputProps={iconPeople}
      {...props}
    />
  );
}
