import { Search } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Form } from "react-router-dom";
import { z } from "zod";
import { getFormProps, useForm } from "@conform-to/react";
import {
  RIE_NumeroImmatriculation_restriction,
  RIE_RaisonSociale_restriction,
  RIE_Prenom_Type_restriction,
  RIE_ReferenceMandat_restriction,
  regexSirenSiret,
  RIE_Nom_Type_restriction,
} from "utils/regex";
import { zDate } from "utils/zDate";
import { intervalToDuration, isBefore } from "date-fns";
import { parseWithZod } from "@conform-to/zod";
import { DateConform } from "components/input/DateField";
import { isDateValid } from "utils/toDate";
import { CheckboxConform } from "components/input/Checkbox";
import { dateFormat, dateFormatShort } from "model/schema";
import { NomInput } from "components/input/NomInput";
import { PrenomInput } from "components/input/PrenomInput";
import { ImmatriculationInput } from "components/input/ImmatriculationInput";
import { RefMandatInput } from "components/input/RefMandatInput";
import { RaisonSocialeInput } from "components/input/RaisonSocialeInput";
import {
  AGE_MINIMUM,
  CHAMP_REQUIS,
  MESSAGE_ERROR_DATE_NAISSANCE_CONDUCTEUR_AGE_MINIMUM,
  MESSAGE_ERROR_FORMAT_DATE_NAISSANCE_CONDUCTEUR,
  MESSAGE_ERROR_FORMAT_DEBUT_NOM_CONDUCTEUR,
  MESSAGE_ERROR_FORMAT_DEBUT_PRENOM_CONDUCTEUR,
  MESSAGE_ERROR_FORMAT_NUMERO_IMMATRICULATION,
  MESSAGE_ERROR_FORMAT_RAISON_SOCIALE,
  MESSAGE_ERROR_FORMAT_REFERENCE_MANDAT,
  MESSAGE_ERROR_FORMAT_SIREN_SIRET,
  MESSAGE_ERROR_MANDAT_OBLIGATOIRE,
  MESSAGE_ERROR_RECHERCHE_HORS_PARC_PRENOM_OU_IMMATRICULATION_OBLIGATOIRE,
  MESSAGE_ERROR_SIRET_OU_RAISON_SOCIALE_OBLIGATOIRE,
  MESSAGE_ERROR_CHAMP_TROP_COURT,
  DATE_NAISSANCE_MIN,
  MESSAGE_ERROR_DATE_NAISSANCE_SOUSCRIPTEUR_MINIMUM,
} from "utils/constantes";
import { SirenSiretInput } from "components/input/SirenSiretInput";
import { TypeRecherche } from "pages/RechercheRIHorsParc/api";
import { ButtonReset } from "components/Buttons";
import { FromError } from "components/FromError";

export const schemaDriver = z
  .object({
    debutNom: z
      .string({ required_error: CHAMP_REQUIS })
      .min(3, MESSAGE_ERROR_CHAMP_TROP_COURT)
      .regex(RIE_Nom_Type_restriction, {
        message: MESSAGE_ERROR_FORMAT_DEBUT_NOM_CONDUCTEUR,
      }),
    prenom: z
      .string()
      .regex(RIE_Prenom_Type_restriction, {
        message: MESSAGE_ERROR_FORMAT_DEBUT_PRENOM_CONDUCTEUR,
      })
      .optional(),

    dateNaissance: zDate({
      format: [dateFormat, dateFormatShort],
      invalid_date: MESSAGE_ERROR_FORMAT_DATE_NAISSANCE_CONDUCTEUR,
      required_error: CHAMP_REQUIS,
    })
      .refine(
        (datePick) => {
          if (!datePick || typeof datePick === "string" || !isDateValid(datePick)) return true;
          return !isBefore(datePick, DATE_NAISSANCE_MIN);
        },
        {
          message: MESSAGE_ERROR_DATE_NAISSANCE_SOUSCRIPTEUR_MINIMUM,
        }
      )
      .refine(
        (datePick) => {
          try {
            if (!datePick || !isDateValid(datePick)) return true;
            const { years = 0 } = intervalToDuration({
              start: datePick,
              end: new Date(),
            });
            return years > AGE_MINIMUM;
          } catch (e) {
            console.log(e);
            return true;
          }
        },
        {
          message: MESSAGE_ERROR_DATE_NAISSANCE_CONDUCTEUR_AGE_MINIMUM,
        }
      ),
    numeroImmatriculation: z
      .string()
      .regex(RIE_NumeroImmatriculation_restriction, {
        message: MESSAGE_ERROR_FORMAT_NUMERO_IMMATRICULATION,
      })
      .optional(),
    mandat: z
      .boolean()
      .default(false)
      .refine((val) => val === true, {
        message: MESSAGE_ERROR_MANDAT_OBLIGATOIRE,
      }),
    refMandat: z
      .string({ required_error: CHAMP_REQUIS })
      .min(3, MESSAGE_ERROR_CHAMP_TROP_COURT)
      .regex(RIE_ReferenceMandat_restriction, {
        message: MESSAGE_ERROR_FORMAT_REFERENCE_MANDAT,
      }),
  })
  .refine(
    (data) => {
      return data.prenom != null || data.numeroImmatriculation != null;
    },
    {
      message: MESSAGE_ERROR_RECHERCHE_HORS_PARC_PRENOM_OU_IMMATRICULATION_OBLIGATOIRE,
    }
  );

export function FormSearchRIbyDriver() {
  const [form, fields] = useForm({
    shouldValidate: "onBlur",
    shouldRevalidate: "onBlur",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: schemaDriver });
    },
  });

  return (
    <Form
      method="GET"
      action="/recherche-ri-hors-parc/vehicules"
      state={{ validateButtonClick: true }}
      {...getFormProps(form)}
    >
      <input type="hidden" name="typeRecherche" value={TypeRecherche.Particulier} />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
        <NomInput fullWidth label="Nom du conducteur " field={fields.debutNom} required />
        <PrenomInput fullWidth label="Prénom du conducteur" field={fields.prenom} />
        <DateConform
          fullWidth
          required
          label="Date de naissance"
          field={fields.dateNaissance}
          openTo="year"
          switchFormat
        />
        <ImmatriculationInput
          fullWidth
          label="Immatriculation"
          field={fields.numeroImmatriculation}
        />
        <Box display={"flex"}>
          <CheckboxConform label="" field={fields.mandat} />
          <RefMandatInput fullWidth label="Réf. mandat" required field={fields.refMandat} />
        </Box>{" "}
        <FromError errors={form.errors} />
        <Box sx={{ display: "flex", gap: 1 }}>
          <ButtonReset />
          <Button
            type="submit"
            variant="contained"
            sx={{ bgcolor: "primary.dark", color: "white", width: "100%" }}
          >
            <Search />
          </Button>
        </Box>
      </Box>
    </Form>
  );
}

export const schemaEntreprise = z
  .object({
    siretOuSiren: z.string().regex(regexSirenSiret, MESSAGE_ERROR_FORMAT_SIREN_SIRET).optional(),
    raisonSociale: z
      .string()
      .min(3, MESSAGE_ERROR_CHAMP_TROP_COURT)
      .regex(RIE_RaisonSociale_restriction, {
        message: MESSAGE_ERROR_FORMAT_RAISON_SOCIALE,
      })
      .optional(),
    mandat: z
      .boolean()
      .default(false)
      .refine((val) => val === true, {
        message: MESSAGE_ERROR_MANDAT_OBLIGATOIRE,
      }),
    refMandat: z
      .string({ required_error: CHAMP_REQUIS })
      .min(3, MESSAGE_ERROR_CHAMP_TROP_COURT)
      .regex(RIE_ReferenceMandat_restriction, {
        message: MESSAGE_ERROR_FORMAT_REFERENCE_MANDAT,
      }),
  })
  .refine(
    (data) => {
      return data.siretOuSiren != null || data.raisonSociale != null;
    },
    {
      message: MESSAGE_ERROR_SIRET_OU_RAISON_SOCIALE_OBLIGATOIRE,
    }
  );

export function FormSearchRIbyEntreprise() {
  const [form, fields] = useForm({
    shouldValidate: "onBlur",
    shouldRevalidate: "onBlur",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: schemaEntreprise });
    },
  });

  return (
    <Form
      method="GET"
      action="/recherche-ri-hors-parc/vehicules"
      state={{ validateButtonClick: true }}
      {...getFormProps(form)}
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
        <input type="hidden" name="typeRecherche" value={TypeRecherche.Societe} />
        <SirenSiretInput field={fields.siretOuSiren} />
        <RaisonSocialeInput fullWidth field={fields.raisonSociale} />
        <Box display={"flex"}>
          <CheckboxConform label="" field={fields.mandat} />
          <RefMandatInput fullWidth label="Réf. mandat" required field={fields.refMandat} />
        </Box>{" "}
        <FromError errors={form.errors} />
        <Box sx={{ display: "flex", gap: 1 }}>
          <ButtonReset />
          <Button
            type="submit"
            variant="contained"
            sx={{ bgcolor: "primary.dark", color: "white", width: "100%" }}
          >
            <Search />
          </Button>
        </Box>
      </Box>
    </Form>
  );
}
