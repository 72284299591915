import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MenuItem } from "./MenuItem";
import { menus } from "profilPermissionsData";
import { Sidebar } from "./Sidebar";
import { HeaderApp } from "./HeaderApp";
import { DrawerHeader, Drawer } from "./Drawer";
import { Alert, AlertColor, CssBaseline, Slide, Snackbar } from "@mui/material";
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { useGetCodesSociete } from "utils/useGetCodeSociete";

import { useGetUser } from "utils/useGetUser";
import { appContext } from "./appContext";
import { ErrorPage } from "pages/500";
import { Role } from "api/auth";

const drawerWidth = 410;

export function Layout() {
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  type SnackbarState = { open: boolean; text: string; type: AlertColor };
  const [snackbar, setSnackBar] = useState<SnackbarState>({
    open: false,
    text: "",
    type: "success",
  });
  const closeSnackbar = () => setSnackBar({ open: false, text: "", type: "success" });
  const openSnackbar = (text: string, type: AlertColor = "success") =>
    setSnackBar({ open: true, text, type });

  const { user, setUser } = useGetUser();
  const { codesSociete, error } = useGetCodesSociete();
  const intermediaireSansHabilitations =
    user?.sansHabilitations && user?.role.toString === Role.INTERMEDIAIRE.toString;
  const societesAuthorisees = user?.societesAuthorisees;

  const navigate = useNavigate();
  useEffect(() => {
    if (intermediaireSansHabilitations) {
      navigate("error-habilitations");
    }
  }, [intermediaireSansHabilitations, navigate]);

  useEffect(() => {
    if (societesAuthorisees !== undefined && !societesAuthorisees) {
      console.log("Layout societesAutorisees:", societesAuthorisees);
      navigate("error-authorisations");
    }
  }, [societesAuthorisees, navigate]);

  if (error) return <ErrorPage />;
  if (!user || !codesSociete) return null;
  return (
    <appContext.Provider
      value={{
        user,
        setUser,
        codesSociete,
        closeSnackbar,
        openSnackbar,
      }}
    >
      <Box sx={{ display: "flex", backgroundColor: "white" }}>
        <HeaderApp
          open={open}
          drawerWidth={drawerWidth}
          user={user}
          onClick={openDrawer}
          intermediaireSansHabilitations={intermediaireSansHabilitations}
          societesNonAuthorisees={!societesAuthorisees}
        />
        <Drawer drawerWidth={drawerWidth} open={open}>
          <DrawerHeader>
            <IconButton onClick={closeDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Box role="navigation">
            <List>
              {menus
                .filter((p) => p.label !== "Administration")
                .map((menu) => (
                  <MenuItem
                    key={menu.label}
                    menu={menu}
                    isOpenDrawer={open}
                    openDrawar={openDrawer}
                    closeDrawer={closeDrawer}
                  />
                ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, m: 2 }}>
          <DrawerHeader />
          <Box sx={{ display: "flex" }}>
            {!intermediaireSansHabilitations && societesAuthorisees && <Sidebar />}
            <Box
              sx={{
                marginLeft: !intermediaireSansHabilitations && societesAuthorisees ? "280px" : 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "background.default",
                  minHeight: "calc(100vh - 100px)",
                  "> div": {
                    p: 2,
                    pt: 1,
                  },
                }}
              >
                <Outlet />
              </Box>
            </Box>
            <CssBaseline />
            <ScrollRestoration />
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbar.open}
        onClose={closeSnackbar}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "left" } as any}
        autoHideDuration={3000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.text}
        </Alert>
      </Snackbar>
    </appContext.Provider>
  );
}
