import { ChangeEvent, forwardRef } from "react";
import { IMaskInput } from "react-imask";
import { Input, InputProps } from "./Input";
import { FieldMetadata, getInputProps } from "@conform-to/react";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

type MaskInputProps = InputProps &
  Partial<CustomProps> & { mask?: string | RegExp; definitions?: any };

export function MaskInput(props: MaskInputProps) {
  const { InputProps, mask, ...other } = props;
  return (
    <Input
      InputProps={{
        inputComponent: TextMaskCustom as any,
        ...InputProps,
      }}
      inputProps={{ mask: mask, definitions: props.definitions }}
      {...other}
    />
  );
}

export type MaskInputConformProps = MaskInputProps & {
  field: FieldMetadata<any, any, any>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export function MaskInputConform({ label, field, ...props }: MaskInputConformProps) {
  return (
    <MaskInput
      label={label}
      {...getInputProps(field, { type: "text" })}
      error={Boolean(field.errors)}
      helperText={field.errors}
      data-cy={field.name}
      {...props}
    />
  );
}
