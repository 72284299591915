import { baseUrl } from "./baseUrl";
import { getUser, removeTokens, removeUser, saveUser, User } from "./auth";
import { removeConducteurs, removeContrat, removeVehicule } from "../pages/CreateReleve/api";

export async function fetchAuth<T = any>(
  url: RequestInfo | URL,
  options: RequestInit = {},
  refresh: boolean = false,
  throws: boolean = true
): Promise<T> {
  if (!!getUser() && !refresh && new Date(getUser().expireAt) < new Date()) {
    return refreshAccessToken().then(() => fetchAuth<T>(url, options, false, false));
  }

  const token = refresh ? getRefreshToken() : getAccessToken();
  if (!token) throw new Error("Access token not available");
  const { headers = {}, ...rest } = options;
  let response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    ...rest,
  });

  if (response.status === 401) {
    removeUser();
    removeTokens();
    removeContrat();
    removeVehicule();
    removeConducteurs();
    window.location.href = "/login";
  }

  if (!response.ok && throws) throw await response.json();
  return response.json();
}

export async function fetchFile<T = any>(
  url: RequestInfo | URL,
  options: RequestInit = {}
): Promise<T> {
  return fetchAuth(url, { method: "POST", ...options });
}
export async function fetchFilePut<T = any>(
  url: RequestInfo | URL,
  options: RequestInit = {}
): Promise<T> {
  return fetchAuth(url, { method: "PUT", ...options });
}

function dateToLocalReplacer(key: string, value: any): any {
  if (value === null) return undefined;
  if (value instanceof Date) {
    value.setHours(2); // GMT+2 = FR (heure d'été) / GMT+1 = FR (heure d'hiver)
    return value;
  } else if (Array.isArray(value)) {
    return value.map((v) => dateToLocalReplacer(key, v));
  } else if (typeof value === "object") {
    const newObj = {} as any;
    for (const prop in value) {
      newObj[prop] = dateToLocalReplacer(prop, value[prop]);
    }
    return newObj;
  }
  return value;
}

export async function fetchJSON<T>(
  url: RequestInfo | URL,
  options: Omit<RequestInit, "body"> & { data?: any } = {}
): Promise<T> {
  const { data, headers, ...rest } = options;
  const body = JSON.stringify(data, dateToLocalReplacer);

  return fetchAuth(url, {
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    ...rest,
    body,
  });
}

export async function downloadFile(url: RequestInfo | URL, filename: string) {
  try {
    const accessToken = getAccessToken();
    if (!accessToken) throw new Error("Access token not available");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) return console.error("request failed");

    const blob = await response.blob();
    // Créer un lien avec le blob
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    // Ajouter le lien à la page
    document.body.appendChild(link);
    // Déclencher le téléchargement
    link.click();
    // Nettoyer
    document.body.removeChild(link);
  } catch (e) {
    console.error("Erreur lors du téléchargement du fichier", e);
  }
}

export function getAccessToken() {
  return localStorage.getItem("ACCESS_TOKEN");
}

export function getRefreshToken() {
  return localStorage.getItem("REFRESH_TOKEN");
}

export async function refreshAccessToken() {
  //alert("refreshAccessToken");
  const url = new URL("/backend/v1/user", baseUrl);
  return fetchAuth<User>(url, undefined, true, false).then((user) => saveUser(user));
}
