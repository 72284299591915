import { useEffect, useState } from "react";
import { getHabilitationList } from "api/habilitation";
import { Habilitation } from "pages/GestionHabilitation/habilitationSchema";

export function useGetHabilitation(codeGestionnaire?: string) {
  const [habilitations, setHabilitation] = useState<Habilitation[]>();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!codeGestionnaire) return;
    getHabilitationList({ codeGestionnaire })
      .then((h) => setHabilitation(h.habilitations))
      .catch(() => setError(true));
  }, [codeGestionnaire]);

  return { habilitations, error };
}
