import { RestartAlt, Search } from "@mui/icons-material";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export function ButtonFilter(props: ButtonProps) {
  const { t } = useTranslation("common");
  return (
    <Button
      type="submit"
      variant="contained"
      startIcon={<Search />}
      sx={{ textTransform: "none" }}
      {...props}
    >
      {t("bouton.filtrer")}
    </Button>
  );
}
export function ButtonReset(props: ButtonProps) {
  const { t } = useTranslation("common");
  return (
    <Tooltip title={t("bouton.reinitialiser")}>
      <Button type="reset" variant="outlined" {...props}>
        <RestartAlt />
      </Button>
    </Tooltip>
  );
}
