import { createBrowserRouter, redirect } from "react-router-dom";
import { Layout } from "pages/Layout/Layout";
import { logout } from "pages/ressouces/logout";
import { action as actionResetFetcher } from "pages/ressouces/resetFetcher";
import { ErrorPage } from "pages/500";
import { OAuth2RedirectHandler } from "pages/LoginRedirect";
import { menus } from "profilPermissionsData";
import { ErrorHabilitations } from "pages/ErrorHabilitations";
import { ErrorAuthorisations } from "pages/ErrorAuthorisations";
import { TypeRecherche } from "pages/RechercheRIHorsParc/api";

const administration = menus[0].permissions;
const AdminByCode = (code: string) => administration.find((p) => p.codePermission === code);

const gestionHabilitation = menus[1].permissions;
const GHByCode = (code: string) => gestionHabilitation.find((p) => p.codePermission === code);

const alimentationRIE = menus[2].permissions;
const A_RIE_ByCode = (code: string) => alimentationRIE.find((p) => p.codePermission === code);

const interrogationnRIE = menus[3].permissions;
const I_RIE_ByCode = (code: string) => interrogationnRIE.find((p) => p.codePermission === code);
const I_RIE_ByCodeAndAdditionalParam = (code: string, additionalParam: string) =>
  interrogationnRIE.find((p) => p.codePermission === code && p.additionalParam === additionalParam);

const suiviTraitements = menus[4].permissions;
const STByCode = (code: string) => suiviTraitements.find((p) => p.codePermission === code);

export const router = createBrowserRouter([
  {
    path: "/login",
    errorElement: <ErrorPage />,
    lazy: () => import("./pages/Login"),
  },
  {
    path: "/logout",
    loader: logout,
    // element: < Logout/>
  },
  {
    path: "/login/redirect",
    element: <OAuth2RedirectHandler />,
  },
  {
    path: "/error-habilitations-login",
    element: <ErrorHabilitations />,
  },
  {
    path: "/error-authorisations-login",
    element: <ErrorAuthorisations />,
  },
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        errorElement: <ErrorPage />,
        lazy: () => import("./pages/Dashborad"),
      },
      {
        path: "error-habilitations",
        element: <ErrorHabilitations isCallInLayout />,
      },
      {
        path: "error-authorisations",
        element: <ErrorAuthorisations isCallInLayout />,
      },
      {
        path: "releves",
        errorElement: <ErrorPage />,
        lazy: () => import("./pages/MesReleves/MesReleves"),
      },
      {
        path: "releves/:id",
        lazy: () => import("./pages/FicheRI/FicheRI"),
        children: [
          {
            index: true,
            loader: () => redirect("synthese"),
          },
          {
            path: "synthese",
            lazy: () => import("./pages/FicheRI/Synthese"),
            children: [
              {
                path: "transfert-gestionnaire",
                lazy: () => import("./pages/FicheRI/Synthese/TransfertGestionnaire"),
              },
              {
                path: "update-contrat",
                lazy: () => import("./pages/FicheRI/Synthese/FormContrat"),
              },
              {
                path: "vehicules/:refPlateforme/update",
                lazy: () => import("./pages/FicheRI/Synthese/FormVehicule"),
              },
              {
                path: "vehicules/add",
                lazy: () => import("./pages/FicheRI/Synthese/FormVehicule"),
              },
              {
                path: "conducteurs/:refPlateforme/update",
                lazy: () => import("./pages/FicheRI/Synthese/FormConducteur"),
              },
              {
                path: "conducteurs/add",
                lazy: () => import("./pages/FicheRI/Synthese/FormConducteur"),
              },
              {
                path: "sinistres/:refPlateforme/update",
                lazy: () => import("./pages/FicheRI/Synthese/FormSinistre"),
              },
              {
                path: "sinistres/add",
                lazy: () => import("./pages/FicheRI/Synthese/FormSinistre"),
              },
            ],
          },
          {
            path: "alertes",
            lazy: () => import("./pages/FicheRI/Alertes"),
          },
          {
            path: "historique-crm",
            lazy: () => import("./pages/FicheRI/HistoriqueCRM"),
          },
          {
            path: "historique-actions",
            lazy: () => import("./pages/FicheRI/HistoriqueActions"),
          },
        ],
      },
      {
        path: "releves-create/*",
        lazy: () => import("./pages/CreateReleve/route"),
        children: [
          {
            index: true,
            loader: () => redirect("contrat"),
          },
          {
            path: "contrat",
            lazy: () => import("./pages/CreateReleve/contrat"),
          },
          {
            path: "vehicules",
            lazy: () => import("./pages/CreateReleve/vehicules"),
          },
          {
            path: "conducteurs",
            lazy: () => import("./pages/CreateReleve/conducteurs"),
          },
        ],
      },
      {
        path: "alertes",
        lazy: () => import("./pages/Alertes/Alertes"),
      },
      {
        path: "recherche-ri-hors-parc/*",
        lazy: () => import("./pages/RechercheRIHorsParc/route"),
        children: [
          {
            index: true,
            loader: () => redirect("souscripteur"),
          },
          {
            path: "souscripteur",
            lazy: () => import("./pages/RechercheRIHorsParc/souscripteur"),
          },
          {
            path: "vehicules",
            lazy: () => import("./pages/RechercheRIHorsParc/vehicules"),
          },
          {
            path: "resultats",
            lazy: () => import("./pages/RechercheRIHorsParc/resultats"),
          },
        ],
      },

      // Administration
      {
        path: AdminByCode("ADMIN_PISTE_AUDIT")?.to,
        lazy: () => import("./pages/404"),
      },
      {
        path: AdminByCode("ADMIN_ACTUALITES")?.to,
        lazy: () => import("./pages/404"),
      },
      {
        path: AdminByCode("ADMIN_AIDE")?.to,
        lazy: () => import("./pages/404"),
      },
      {
        path: AdminByCode("ADMIN_ORGANISATION")?.to,
        lazy: () => import("./pages/ParametrageSociete/ParametrageSociete"),
      },
      {
        path: "admin-organisation/societes/:id",
        lazy: () => import("./pages/ParametrageSociete/Societe"),
        children: [
          {
            index: true,
            loader: () => redirect("informations"),
          },
          {
            path: "informations",
            lazy: () => import("./pages/ParametrageSociete/Informations"),
          },
          {
            path: "canaux",
            lazy: () => import("./pages/ParametrageSociete/Canaux"),
          },
          {
            path: "frequences",
            lazy: () => import("./pages/ParametrageSociete/Frequences"),
          },
          {
            path: "alertes",
            lazy: () => import("./pages/ParametrageSociete/Aletes"),
          },
        ],
      },
      {
        path: AdminByCode("ADMIN_PROFILS")?.to,
        lazy: () => import("./pages/ParametrageProfil/ParametrageProfil"),
        children: [
          {
            path: ":id/modal",
            lazy: () => import("./pages/ParametrageProfil/ModalProfil"),
          },
        ],
      },
      {
        path: AdminByCode("ADMIN_CERTIFICATS_CLIENT")?.to,
        lazy: () => import("./pages/Certificats/Certificats"),
      },

      // Suivi des traitements
      {
        path: STByCode("SUIVI_TRAITEMENT_REJETS")?.to,
        lazy: () => import("./pages/404"),
      },
      {
        path: STByCode("SUIVI_TRAITEMENT_ALERTES")?.to,
        lazy: () => import("./pages/Alertes/Alertes"),
      },
      {
        path: STByCode("SUIVI_TRAITEMENT_FREQUENCES")?.to,
        lazy: () => import("./pages/SuiviEtats/SuiviEtats"),
      },
      {
        path: STByCode("SUIVI_TRAITEMENT_TRANSFERTS_DIFF")?.to,
        lazy: () => import("./pages/404"),
      },
      //  Consultation et gestion des habilitations
      {
        path: GHByCode("HABILITATION_CONSULTATION")?.to,
        lazy: () => import("./pages/GestionHabilitation/GestionHabilitations"),
      },
      {
        path: GHByCode("HABILITATION_GESTION")?.to,
        lazy: () => import("./pages/GestionHabilitation/GestionHabilitations"),
      },

      //Alimentation du RIE
      {
        path: A_RIE_ByCode("ALIM_RI_SUIVI_FICHIER")?.to,
        lazy: () => import("./pages/SuiviAlimentationExtranet/SuiviAlimentationExtranet"),
      },
      {
        path: A_RIE_ByCode("ALIM_RI_FICHIER")?.to,
        lazy: () => import("./pages/AlimentationRIFicherExcel/AlimentationRIFichierExcel"),
      },
      {
        path: A_RIE_ByCode("ALIM_RI_FICHIER")?.to + "/validation",
        lazy: () => import("./pages/AlimentationRIFicherExcel/Validation"),
      },
      {
        path: A_RIE_ByCode("ALIM_RI_FICHIER")?.to + "/*",
        lazy: () => import("./pages/AlimentationRIFicherExcel/Steps/Steps"),
        children: [
          {
            path: "contrat",
            lazy: () => import("./pages/AlimentationRIFicherExcel/Steps/Contrat"),
          },
          {
            path: "vehicules",
            lazy: () => import("./pages/AlimentationRIFicherExcel/Steps/Vehicules"),
          },
          {
            path: "conducteurs",
            lazy: () => import("./pages/AlimentationRIFicherExcel/Steps/Conducteurs"),
          },
          {
            path: "sinistres",
            lazy: () => import("./pages/AlimentationRIFicherExcel/Steps/Sinsitres"),
          },
        ],
      },

      {
        path: A_RIE_ByCode("ALIM_RI_UNITAIRE")?.to,
        loader: () => redirect("/releves-create"),
      },
      {
        path: A_RIE_ByCode("ALIM_RI_TRANSFERTS_MASSE")?.to,
        lazy: () => import("./pages/404"),
      },
      {
        path: I_RIE_ByCode("INTERRO_RI_CONFORMITE")?.to,
        lazy: () => import("./pages/InterogationRIFichierExcel/InterogationRIFIchierExcel"),
      },
      {
        path: I_RIE_ByCodeAndAdditionalParam("INTERRO_RI_INFORMATION", TypeRecherche.Particulier)
          ?.to,
        loader: () =>
          redirect(
            `/recherche-ri-hors-parc/souscripteur?typeRecherche=${TypeRecherche.Particulier}`
          ),
      },
      {
        path: I_RIE_ByCodeAndAdditionalParam("INTERRO_RI_INFORMATION", TypeRecherche.Societe)?.to,
        loader: () =>
          redirect(`/recherche-ri-hors-parc/souscripteur?typeRecherche=${TypeRecherche.Societe}`),
      },
      {
        path: I_RIE_ByCode("INTERRO_RI_MES_RELEVES")?.to,
        loader: () => redirect("/releves"),
      },
      {
        path: I_RIE_ByCode("GESTION_RI_MES_RELEVES")?.to,
        loader: () => redirect("/releves"),
      },
      {
        path: I_RIE_ByCode("INTERRO_RI_SUIVI_FICHIER")?.to,
        lazy: () => import("./pages/SuiviEchangeTechniques/EchangeTechniques"),
      },

      // Autres
      {
        path: "help",
        lazy: () => import("./pages/Help"),
      },
      {
        path: "profil",
        lazy: () => import("./pages/Profil"),
      },

      // RESSOURCES
      {
        path: "reset-fetcher",
        action: actionResetFetcher,
      },
      {
        path: "infos-siv",
        lazy: () => import("./pages/ressouces/infos-siv"),
      },

      // 404
      {
        path: "*",
        lazy: () => import("./pages/404"),
      },
    ],
  },
]);
