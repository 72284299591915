import { RIE_Prenom_Type_restriction } from "utils/regex";
import { MaskInputConform, MaskInputConformProps } from "./MaskInput";
import { iconPeople } from "utils/endAdornmentIcons";

export function PrenomInput({ label, field, ...props }: MaskInputConformProps) {
  return (
    <MaskInputConform
      label={label ?? "Prenom"}
      mask={RIE_Prenom_Type_restriction}
      field={field}
      InputProps={iconPeople}
      {...props}
    />
  );
}
