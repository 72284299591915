import { regexSiretForInput } from "utils/regex";
import { MaskInputConform, MaskInputConformProps } from "./MaskInput";

export function SirenSiretInput({ label, field, ...props }: MaskInputConformProps) {
  return (
    <MaskInputConform
      label={label ?? "SIREN/SIRET"}
      mask={regexSiretForInput}
      field={field}
      {...props}
    />
  );
}
