import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

type TranslateParagraphProps = TypographyProps & {
  text?: String;
  t: any;
  i18n: any;
  tReady: boolean;
};
function TranslateParagraph({
  text,
  t,
  // Récupérer i18n et tReady pour éviter de les mettre dans les props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  i18n,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tReady,
  ...props
}: TranslateParagraphProps) {
  return (
    <Typography component={"span"} {...props}>
      {t(text)}
    </Typography>
  );
}

export default withTranslation("common")(TranslateParagraph);
