import { z } from "zod";
import { parse, isBefore, isAfter, isValid } from "date-fns";
import fr from "date-fns/locale/fr";
import { dateFormat, required } from "model/schema";
import { isDateValid } from "./toDate";
import { MESSAGE_ERROR_FORMAT_INVALIDE } from "./constantes";

type MinMax = { message: string; getValue: (date?: Date) => Date };

type zDateProps = {
  format?: string | [string, string];
  required_error?: string;
  invalid_date?: string;
  min?: MinMax;
  max?: MinMax;
};

export function zDate({
  format = dateFormat,
  invalid_date = MESSAGE_ERROR_FORMAT_INVALIDE,
  required_error = required.required_error,
  min,
  max,
}: zDateProps = {}) {
  return z
    .string({ required_error })
    .refine(
      (val) => {
        try {
          if (Array.isArray(format)) {
            const [format1, format2] = format;
            const date1 = parse(val, format1, new Date(), { locale: fr });
            const date2 = parse(val, format2, new Date(), { locale: fr });

            return isValid(date1) || isValid(date2);
          } else {
            const date = parse(val, format, new Date(), { locale: fr });
            return isValid(date);
          }
        } catch {
          return false;
        }
      },
      { message: invalid_date }
    )
    .transform((val, ctx) => {
      let date: Date;
      try {
        if (Array.isArray(format)) {
          const [format1, format2] = format;
          const date1 = parse(val, format1, new Date(), { locale: fr });
          const date2 = parse(val, format2, new Date(), { locale: fr });

          if (isDateValid(date1)) date = date1;
          else if (isDateValid(date2)) date = date2;
          else date = new Date();

          return date;
        } else {
          date = parse(val, format, new Date(), { locale: fr });
        }
      } catch (e) {
        console.error({ e });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: invalid_date,
        });
        return z.NEVER;
      }

      if (min) {
        const minValue = min.getValue(date);
        minValue.setHours(0, 0, 0, 0);

        if (isBefore(date, minValue)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: min?.message,
          });
          return z.NEVER;
        }
      }

      if (max) {
        const maxValue = max.getValue(date);
        maxValue.setHours(0, 0, 0, 0);

        if (isAfter(date, maxValue)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: max.message,
          });
          return z.NEVER;
        }
      }

      return date;
    });
}
