import { RIE_NumeroImmatriculation_restriction_for_input } from "utils/regex";
import { MaskInputConform, MaskInputConformProps } from "./MaskInput";
import { iconCar } from "utils/endAdornmentIcons";

export function ImmatriculationInput({ label, field, ...props }: MaskInputConformProps) {
  return (
    <MaskInputConform
      label={label ?? "Immatriculation"}
      field={field}
      mask={RIE_NumeroImmatriculation_restriction_for_input}
      {...props}
      InputProps={iconCar}
    />
  );
}
