import { Add, List, Notifications, SwapHoriz } from "@mui/icons-material";
import { Box, IconButton, Tab, Tabs, Typography, styled, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { FormSearchRIbyDriver, FormSearchRIbyEntreprise } from "./FormSearchRI";
import { useUser } from "utils/useGetUser";
import { useGetHabilitation } from "utils/useGetHabilitation";
import { Role } from "api/auth";
import { useTranslation } from "react-i18next";

export function Sidebar() {
  const user = useUser();
  const { habilitations } = useGetHabilitation(user?.codeSociete);
  const isGestSinistre = habilitations?.every((h) => h.perimetre === "S");
  const { t } = useTranslation("common");

  return (
    <Box
      bgcolor="background.default"
      sx={{
        zIndex: 3,
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 1,
        width: "270px",
        height: "calc(100vh - 64px)",
        p: 1,
        pb: 6,
        overflowX: "hidden",
      }}
    >
      <Typography variant="h2" color="primary.main">
        {user?.role === Role.GPSA ? t("sidebar.ri_gpsa.title") : t("sidebar.ri_nogpsa.title")}
      </Typography>
      {user.authorities.includes("ALIM_RI_UNITAIRE") &&
        ((user.role === Role.INTERMEDIAIRE && !isGestSinistre) || user.role === Role.ASSUREUR) && (
          <NavLinkCustom to="releves-create" icon={<Add />} text=" Créer un relevé" />
        )}
      {(user.authorities.includes("INTERRO_RI_MES_RELEVES") ||
        user.authorities.includes("GESTION_RI_MES_RELEVES")) && (
        <NavLinkCustom
          to="releves"
          icon={<List />}
          text={user?.role === Role.GPSA ? "Relevés d'information" : "Mes relevés"}
          end
        />
      )}
      {user.authorities.includes("SUIVI_TRAITEMENT_ALERTES") && (
        <NavLinkCustom
          to="alertes"
          icon={<Notifications />}
          text={user?.role === Role.GPSA ? "Alertes" : "Mes alertes"}
        />
      )}
      {user.authorities.includes("INTERRO_RI_INFORMATION") &&
        ((user.role === Role.INTERMEDIAIRE && !isGestSinistre) ||
          user.role === Role.ASSUREUR ||
          user.role === Role.GPSA) && (
          <>
            <Separator />
            <TabsSearchRi />
          </>
        )}
    </Box>
  );
}

const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: "white",
  marginRight: 6,
  width: "40%",
  textTransform: "none",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

export function TabsSearchRi() {
  const [value, setValue] = useState(1);
  const { t } = useTranslation("common");

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderColor: "divider", padding: "3px" }}>
        <Typography variant="h2" color="primary.main">
          {t("sidebar.recherche.title")}
        </Typography>
        <Tabs
          value={value}
          sx={{ ".MuiTabs-indicator": { display: "none" } }}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <IconButton onClick={() => setValue(value === 1 ? 2 : 1)}>
            <SwapHoriz color="primary" />
          </IconButton>
          <StyledTab label="Particulier" {...a11yProps(1)} />
          <StyledTab label="Société" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={1}>
        <FormSearchRIbyDriver />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <FormSearchRIbyEntreprise />
      </CustomTabPanel>
    </Box>
  );
}

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function CustomTabPanel({ children, value, index, ...rest }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function NavLinkCustom({
  text,
  icon,
  ...props
}: NavLinkProps & { text: ReactNode; icon: ReactNode }) {
  const theme = useTheme();
  return (
    <Box
      component={NavLink}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
        p: 1,
      }}
      style={({ isActive }) => ({
        backgroundColor: isActive ? theme.palette.primary.main : theme.palette.primary.light,
        color: isActive ? "white" : theme.palette.primary.main,
      })}
      {...props}
    >
      {icon}
      <Typography fontWeight="bold" sx={{ fontWeight: "normal" }}>
        {text}
      </Typography>
    </Box>
  );
}

function Separator() {
  return (
    <Box
      sx={{
        borderBottom: "1px solid #d2d2d2",
        width: "110%",
        ml: -1,
        color: "grey",
      }}
    />
  );
}
