import { redirect } from "react-router-dom";
import { useEffect } from "react";
import { intervalToDuration } from "date-fns";
import { ReleveHorsParc } from "api/releves";

export enum TypeRecherche {
  Particulier = "particulier",
  Societe = "societe",
}

export const KEY_STORAGE_RESULTATS = "resultats";
export const KEY_STORAGE_LOGOS = "logos";
export function getResultats(): ReleveHorsParc[] {
  const resultatsRaw = sessionStorage.getItem(KEY_STORAGE_RESULTATS);
  return resultatsRaw ? JSON.parse(resultatsRaw) : [];
}
export function removeResultats() {
  sessionStorage.removeItem(KEY_STORAGE_RESULTATS);
}
export function setResultats(resultats: ReleveHorsParc[]) {
  setLastUpdate();
  sessionStorage.setItem(KEY_STORAGE_RESULTATS, JSON.stringify(resultats));
}
export function getLogos(): Map<string, ArrayBuffer | null> | null {
  const resultatsRaw = sessionStorage.getItem(KEY_STORAGE_LOGOS);
  return resultatsRaw ? JSON.parse(resultatsRaw) : [];
}
export function removeLogos() {
  sessionStorage.removeItem(KEY_STORAGE_LOGOS);
}
export function setLogos(logos: Map<string, ArrayBuffer | null> | null) {
  setLastUpdate();
  sessionStorage.setItem(KEY_STORAGE_LOGOS, JSON.stringify(logos));
}

export function protectSteps(step: "souscripteur" | "vehicules" | "resultats") {
  const resultats = getResultats();
  switch (step) {
    case "vehicules": {
      if (resultats?.length > 0) {
        removeResultats();
      }
      break;
    }

    case "resultats": {
      if (!resultats?.length) {
        const search = window.location.search;
        throw redirect(`/recherche-ri-hors-parc/vehicules${search}`);
      }
      break;
    }

    case "souscripteur": {
      if (resultats?.length > 0) {
        removeResultats();
      }
      break;
    }
    default:
      break;
  }
}

const KEY_STORAGE_DATE_UPDATE = "date_update";
export function getLastUpdate(): Date | undefined {
  const dateRaw = sessionStorage.getItem(KEY_STORAGE_DATE_UPDATE);
  return dateRaw ? new Date(dateRaw) : undefined;
}
export function setLastUpdate(date: Date = new Date()) {
  sessionStorage.setItem(KEY_STORAGE_DATE_UPDATE, String(date));
}
export function removeDateUpdate() {
  return sessionStorage.removeItem(KEY_STORAGE_DATE_UPDATE);
}

export function useResetWorkflow(hoursToResetForm = 24) {
  useEffect(() => {
    const lastUpdate = getLastUpdate();
    if (!lastUpdate) return;
    const duration = intervalToDuration({ start: lastUpdate, end: Date.now() });
    console.log("lastUpdate in hours", duration?.hours);
    if (Number(duration?.hours) >= hoursToResetForm) {
      console.log("Form create revele has been delete");
      removeResultats();
    }
  }, [hoursToResetForm]);
}
