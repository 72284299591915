import { RIE_RaisonSociale_restriction } from "utils/regex";
import { MaskInputConform, MaskInputConformProps } from "./MaskInput";

export function RaisonSocialeInput({ label, field, ...props }: MaskInputConformProps) {
  return (
    <MaskInputConform
      label={label ?? "Raison sociale"}
      mask={RIE_RaisonSociale_restriction}
      field={field}
      {...props}
    />
  );
}
