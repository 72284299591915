export enum Role {
    NONE = "NONE",
    ASSUREUR = "Assureur",
    INTERMEDIAIRE = "Intermediaire",
    GPSA = "GPSA",
}

export type User = {
    id: number;
    authorities: string[];
    codeProfil: string;
    codeSociete: string;
    userName: string;
    nomProfil: string;
    nomSociete: string;
    role: Role;
    sansHabilitations: boolean;
    accessToken?: string;
    expireAt: any;
    refreshToken?: string;
    societesAuthorisees: boolean;
};

export function getUser(): User {
    return JSON.parse(localStorage.getItem("user") ?? "{}");
}

export function saveUser(user: User) {
    let treatedUser = user;
    if (
        user?.authorities?.includes("HABILITATION_GESTION") &&
        user?.authorities?.includes("HABILITATION_CONSULTATION")
    ) {
        treatedUser.authorities.splice(user.authorities.indexOf("HABILITATION_CONSULTATION"), 1);
    }

    localStorage.setItem("user", JSON.stringify(treatedUser));
    if (!!user.accessToken) localStorage.setItem("ACCESS_TOKEN", user.accessToken);
    if (!!user.refreshToken) localStorage.setItem("REFRESH_TOKEN", user.refreshToken);
}

export function removeUser() {
    localStorage.removeItem("user");
}

export function removeTokens() {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
}
