import { ExitToApp, Help, Menu as MenuIcon, PersonOutline } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Toolbar,
  Menu,
  MenuItem,
  Fab as FabMd,
  FabProps,
  CircularProgress,
} from "@mui/material";
import { NavLink, useNavigate, useNavigation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useState } from "react";
import { settings } from "profilPermissionsData";
import { User } from "api/auth";
import { useUser } from "utils/useGetUser";

const Fab = (props: FabProps) => (
  <FabMd size="small" sx={{ color: "primary.main", background: "white" }} {...props} />
);

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "drawerWidth",
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.default,
  boxShadow: "none",
  color: "black",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function HeaderApp({
  open,
  drawerWidth,
  intermediaireSansHabilitations,
  onClick,
}: {
  open: boolean;
  user: User;
  drawerWidth: number;
  intermediaireSansHabilitations?: boolean;
  societesNonAuthorisees?: boolean;
  onClick: () => void;
}) {
  const navigation = useNavigation();
  const isLoading = navigation.state !== "idle";
  const user = useUser();

  return (
    <AppBar position="fixed" drawerWidth={drawerWidth} open={open}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          {!intermediaireSansHabilitations && (
            // {!intermediaireSansHabilitations && !intermediaireSansSocietesAuthorisees && (
            <IconButton
              aria-label="open drawer"
              onClick={onClick}
              edge="start"
              sx={{
                color: "primary.main",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <NavLink to="/">
            <img src="/logo.svg" alt="logo" height="51px" />
          </NavLink>
          {isLoading && <CircularProgress size={20} />}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <NavLink to="help">
            <Fab>
              <Help />
            </Fab>
          </NavLink>
          <SettingsMenu />
          <NavLink to="profil">
            <Fab
              sx={{
                width: "unset",
                borderRadius: "35px",
                pl: "12px",
                pr: "16px",
                bgcolor: "white",
              }}
            >
              <PersonOutline color="primary" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  color: "primary.main",
                  textTransform: "none",
                  ml: 1,
                }}
              >
                <Typography sx={{ fontSize: 10, fontWeight: "bold", lineHeight: 1 }}>
                  {user?.nomSociete}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    textAlign: "start",
                    fontWeight: "bold",
                    lineHeight: 1.1,
                    textTransform: "capitalize",
                  }}
                >
                  {user?.nomProfil}
                </Typography>
              </Box>
            </Fab>
          </NavLink>
          <NavLink to="logout">
            <Fab>
              <ExitToApp />
            </Fab>
          </NavLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export function SettingsMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const navigate = useNavigate();
  const user = useUser();

  const menus = settings.permissions.filter((setting) =>
    user.authorities.includes(setting.codePermission)
  );

  if (!menus.length) return null;

  return (
    <>
      <Fab
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {settings.icon}
      </Fab>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {menus.map((setting) => (
          <MenuItem
            key={setting.to}
            onClick={() => {
              navigate(setting.to ?? "");
              handleClose();
            }}
          >
            {setting.libellePermission}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
