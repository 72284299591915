import { AlertColor } from "@mui/material";
import { User } from "api/auth";
import { CodeSociete } from "api/codesSocietes";
import { createContext, useContext } from "react";

export type ContextType = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  codesSociete: CodeSociete[];
  closeSnackbar: () => void;
  openSnackbar: (text: string, type?: AlertColor) => void;
} | null;

export const appContext = createContext<ContextType>(null);

export const Provider = appContext.Provider;

export function useAppContext() {
  const context = useContext(appContext);
  if (!context) throw new Error("Pas de Provider useAppContext");
  return context;
}

export function useSociete() {
  const context = useAppContext();
  return context.codesSociete;
}
