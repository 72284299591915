import { InputAdornment } from "@mui/material";
import { DirectionsCar, Person } from "@mui/icons-material";

export const iconCar = {
  endAdornment: (
    <InputAdornment position="end">
      <DirectionsCar color="primary" />
    </InputAdornment>
  ),
};

export const iconPeople = {
  endAdornment: (
    <InputAdornment position="end">
      <Person color="primary" />
    </InputAdornment>
  ),
};

export const iconNumeroPolice = {
  endAdornment: (
    <InputAdornment color="primary" position="end">
      <img src="/icon-numero-police.svg" alt="icon numéro police" />
    </InputAdornment>
  ),
};
