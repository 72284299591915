import type { Contrat, Vehicule, Conducteur } from "model/releveInformation";
import { redirect } from "react-router-dom";
import { useEffect } from "react";
import { intervalToDuration } from "date-fns";
import { formatSafe } from "utils/toDate";
import { dateFormatShort } from "model/schema";

export const KEY_STORAGE_CONTRAT = "contrat";
export function getContratFromStorageForSteps(): Contrat {
  const contratRaw = sessionStorage.getItem(KEY_STORAGE_CONTRAT);
  if (contratRaw) {
    const contrat = JSON.parse(contratRaw);
    if (contrat?.dateDebut) {
      contrat.dateDebut = formatSafe(new Date(contrat.dateDebut));    
    }  
    if (contrat?.dateFin) {
      contrat.dateFin = formatSafe(new Date(contrat.dateFin));    
    }
    if (contrat?.dateInformation) {
      contrat.dateInformation = formatSafe(new Date(contrat.dateInformation));    
    } 
    if (contrat?.dateNaissance && !contrat?.dateNaissancePartiel) {
      contrat.dateNaissance = formatSafe(new Date(contrat.dateNaissance));    
    } else if(contrat?.dateNaissance && contrat?.dateNaissancePartiel){
      contrat.dateNaissance = formatSafe(new Date(contrat.dateNaissance),dateFormatShort); 
    }
  
    if (contrat?.dateApplicationCRM) {
      contrat.dateApplicationCRM = formatSafe(new Date(contrat.dateApplicationCRM));    
    } 
    return contrat;
  } else {
    return {} as Contrat;
  }
}
export function getContrat(): Contrat {
  const contratRaw = sessionStorage.getItem(KEY_STORAGE_CONTRAT);
  return contratRaw ? JSON.parse(contratRaw) : {};
}
export function setContrat(contrat: Contrat) {
  setLastUpdate();
    sessionStorage.setItem(KEY_STORAGE_CONTRAT, JSON.stringify(contrat));
}
export function removeContrat() {
  return sessionStorage.removeItem(KEY_STORAGE_CONTRAT);
}

export const KEY_STORAGE_VEHICULES = "vehicules";
export function getVehicules(): Vehicule[] {
  const vehiculesRaw = sessionStorage.getItem(KEY_STORAGE_VEHICULES);
  return vehiculesRaw ? JSON.parse(vehiculesRaw) : [];
}
export function removeVehicule() {
  setLastUpdate();
  return sessionStorage.removeItem(KEY_STORAGE_VEHICULES);
}
export function setVehicules(vehicules: Vehicule[]) {
  sessionStorage.setItem(KEY_STORAGE_VEHICULES, JSON.stringify(vehicules));
}

export const KEY_STORAGE_CONDUCTEUR = "conducteurs";
export function getConducteurs(): Conducteur[] {
  const conducteursRaw = sessionStorage.getItem(KEY_STORAGE_CONDUCTEUR);
  return conducteursRaw ? JSON.parse(conducteursRaw) : [];
}
export function removeConducteurs() {
  sessionStorage.removeItem(KEY_STORAGE_CONDUCTEUR);
}
export function setConducteurs(conducteurs: Conducteur[]) {
  setLastUpdate();
  sessionStorage.setItem(KEY_STORAGE_CONDUCTEUR, JSON.stringify(conducteurs));
}

export function removeAllForm() {
  for (let key of [KEY_STORAGE_CONTRAT, KEY_STORAGE_VEHICULES, KEY_STORAGE_CONDUCTEUR]) {
    sessionStorage.removeItem(key);
  }
  removeDateUpdate();
}

export function protectSteps(step: "contrat" | "vehicules" | "conducteur") {
  const [contrat, vehicules] = [getContrat(), getVehicules()];
    switch (step) {
    case "vehicules": {
      if (!Object.keys(contrat)?.length) throw redirect("/releves-create/contrat");
      break;
    }

    case "conducteur": {
      if (!Object.keys(contrat)?.length) throw redirect("/releves-create/contrat");
      if (!vehicules?.length) throw redirect("/releves-create/vehicules");
      break;
    }
    default:
      break;
  }
}

const KEY_STORAGE_DATE_UPDATE = "date_update";
export function getLastUpdate(): Date | undefined {
  const dateRaw = sessionStorage.getItem(KEY_STORAGE_DATE_UPDATE);
  return dateRaw ? new Date(dateRaw) : undefined;
}
export function setLastUpdate(date: Date = new Date()) {
  sessionStorage.setItem(KEY_STORAGE_DATE_UPDATE, String(date));
}
export function removeDateUpdate() {
  return sessionStorage.removeItem(KEY_STORAGE_DATE_UPDATE);
}

export function useResetWorkflow(hoursToResetForm = 24) {
  useEffect(() => {
    const lastUpdate = getLastUpdate();
    if (!lastUpdate) return;
    const duration = intervalToDuration({ start: lastUpdate, end: Date.now() });
    if (Number(duration?.hours) >= hoursToResetForm) {
      removeAllForm();
    }
  }, [hoursToResetForm]);
}
