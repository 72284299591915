import { parse, format, isValid } from "date-fns";
import { VALEUR_NON_DEFINI } from "model/schema";

export const FORMAT_DATE = "dd/MM/yyyy";
export const FORMAT_ISO = "yyyy-MM-dd";
export const FORMAT_DATE_PARTIEL = "MM/yyyy";
export const FORMAT_DATE_TIME_ISO = "yyyy-MM-ddTHH:mm:ss.t";
export const FORMAT_DATE_TIME = "dd/MM/yyyy à HH:mm";

export const toDate = (str?: string | null, _format = FORMAT_DATE) => {
  try {
    if (!str) return null;
    const parsedDate = parse(str, _format, new Date());
    return isValid(parsedDate) ? parsedDate : null;
  } catch {
    return null;
  }
};

export const isDatePartiel = (str?: string | null): boolean => {
  if (!str) return false;
  return toDate(str, FORMAT_DATE_PARTIEL) !== null;
};

export const formatSafe = (date?: Date | string | null, _format = FORMAT_DATE) => {
  try {
    if (!date) return null;
    if (date instanceof Date) {
      return format(date, _format);
    }
    return format(new Date(date), _format);
  } catch {
    return null;
  }
};

export const dateToDefaultValue = (date?: Date | string | null) => {
  return formatSafe(date) as any;
};
export const dateNaissanceToDefaultValue = (
  date?: Date | string | null,
  dateNaissancePartiel = false
) => {
  return formatSafe(date, dateNaissancePartiel ? FORMAT_DATE_PARTIEL : FORMAT_DATE) as any;
};

export const formatSafeDateTime = (date?: string | null, _format = FORMAT_DATE_TIME) => {
  try {
    if (!date) return null;
    return format(new Date(date), _format);
  } catch {
    return null;
  }
};

export const isDateValid = (date: Date) => !Number.isNaN(date.valueOf());

export const printDateNaissance = (date?: Date | null, datePartiel: boolean = false) => {
  try {
    return formatSafe(date, datePartiel ? FORMAT_DATE_PARTIEL : FORMAT_DATE) || VALEUR_NON_DEFINI;
  } catch {
    return VALEUR_NON_DEFINI;
  }
};
