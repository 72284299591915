import { RIE_ReferenceMandat_restriction } from "utils/regex";
import { MaskInputConform, MaskInputConformProps } from "./MaskInput";

export function RefMandatInput({ field, label, ...props }: MaskInputConformProps) {
  return (
    <MaskInputConform
      label={label ?? "Référence mandat"}
      mask={RIE_ReferenceMandat_restriction}
      field={field}
      {...props}
    />
  );
}
