import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { dateFormat, dateFormatShort } from "model/schema";
import { useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { MoreTime } from "@mui/icons-material";
import {
  isDateValid,
  toDate,
  FORMAT_DATE_TIME_ISO,
  FORMAT_ISO,
  FORMAT_DATE_PARTIEL,
} from "utils/toDate";
import { FieldMetadata, useInputControl } from "@conform-to/react";
import { MESSAGE_ERROR_FORMAT_DATE } from "utils/constantes";
import { ErrorParagraph } from "components/ErrorParagraph";

type DateFieldProps = DatePickerProps<any> & {
  name?: string;
  error?: boolean;
  controlFormat?: boolean;
  helperText?: string[];
  required?: boolean;
  fullWidth?: boolean;
  switchFormat?: boolean;
  hideDisplayFormat?: boolean;
  onSwitchFormat?: () => void;
  defaultSwitchedFormat?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (e: any) => void;
  field: FieldMetadata<any>;
};

function DateStyled(props: DateFieldProps) {
  const [errorFormat, setStateErrorFormat] = useState(false);
  const controlFormat = Boolean(props.controlFormat);

  const width = props.switchFormat ? "100%" : "300px";

  const minDate = props.minDate ? new Date(props.minDate.setHours(0, 0, 0, 0)) : undefined;
  const maxDate = props.maxDate ? new Date(props.maxDate.setHours(0, 0, 0, 0)) : undefined;
  const shouldDisableMinDate = (date: Date) => {
    return (minDate && date < minDate) ?? false;
  };

  const shouldDisableMaxDate = (date: Date) => {
    return (maxDate && date > maxDate) ?? false;
  };

  const shouldDisableDate = (date: Date) => {
    return shouldDisableMinDate(date) || shouldDisableMaxDate(date);
  };

  // Récupérer le field ainsi que ses propriétés dans le formulaire (servira à mettre à jour la valeur lors d'une séléction pour effectuer la validation)
  const date = useInputControl(props.field as any);
  // Récupérer l'input html créé par le datePicker (servira à déclencher la validation avec le blur)
  const datePickerInputRef = useRef<HTMLInputElement>(null);
  return (
    <Box sx={{ position: "relative", width: props.fullWidth ? "100%" : "unset" }}>
      <DatePicker
        format={dateFormat}
        inputRef={datePickerInputRef}
        //Lorsque le calendrier s'ouvre
        onOpen={() => {
          // Mettre le focus sur l'input (cela déclenchera le blur et donc la validation lorsque l'on cliquera ailleurs)
          datePickerInputRef.current?.focus();
        }}
        // Lorsque que la valeur est sélectionné dans le calendrier
        onChange={(v) => {
          // Mettre à jour la valeur dans le modèle pour la validation
          date.change(v as any);
        }}
        localeText={{
          fieldDayPlaceholder: () => "JJ",
          fieldMonthPlaceholder: () => "MM",
          fieldYearPlaceholder: () => "AAAA",
        }}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
          field: { clearable: true },
          textField: {
            FormHelperTextProps: { style: { fontWeight: "bold" } },
            name: props.name,
            variant: "filled",
            size: "small",
            error: props.error,
            helperText: (
              <>
                {props.helperText?.map((text) => (
                  <ErrorParagraph error={text} key={text} inputFieldError />
                ))}
                {controlFormat && errorFormat && (
                  <ErrorParagraph
                    error={MESSAGE_ERROR_FORMAT_DATE}
                    key={MESSAGE_ERROR_FORMAT_DATE}
                    inputFieldError
                  />
                )}
              </>
            ),
            required: props.required,
            fullWidth: props.fullWidth,
            //      InputProps: { endAdornment: <img src="/icon-date.svg" alt="icon date" /> },
            sx: {
              width: props.fullWidth ? "100%" : width,
            },
            // Lorsque la valeur est saisie ou clear
            onChange: (e) => {
              if (!props.error && e?.toString() === "Invalid Date") {
                setStateErrorFormat(true);
              } else {
                setStateErrorFormat(false);
              }
              // Mets à jour automatiquement la valeur du modèle, sauf qu'elle est vide -> le faire manuellement
              if (e === null) {
                date.change(undefined);
              }
              if (props.onChange) {
                props.onChange(e);
              }
            },
          },
        }}
        {...props}
        shouldDisableDate={
          maxDate && minDate
            ? shouldDisableDate
            : minDate
            ? shouldDisableMinDate
            : maxDate
            ? shouldDisableMaxDate
            : undefined
        }
      />
      {!props.hideDisplayFormat && (
        <Box sx={{ position: "absolute", right: 0, mt: "5px" }}>
          <Typography fontSize={11} color="primary">
            {"MM/yyyy" === props.format ? "MM/AAAA" : "JJ/MM/AAAA"}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export function DateField(props: DateFieldProps) {
  const { switchFormat, onSwitchFormat, defaultSwitchedFormat } = props;
  const [toggleFormat, setToggle] = useState(defaultSwitchedFormat ?? false);

  if (switchFormat)
    return (
      <Box sx={{ position: "relative", width: props.fullWidth ? "100%" : "300px" }}>
        <Box sx={{ width: "calc(100% - 30px)" }}>
          <DateStyled
            format={toggleFormat ? dateFormatShort : dateFormat}
            views={toggleFormat ? ["month", "year"] : undefined}
            {...props}
          />
        </Box>
        {switchFormat && (
          <Box sx={{ position: "absolute", right: "-8px", top: "5px" }}>
            <IconButton
              aria-label="toggle-format-date"
              color={toggleFormat ? "primary" : undefined}
              onClick={(e) => {
                e.preventDefault();
                setToggle((t) => !t);
                onSwitchFormat?.();
              }}
            >
              <MoreTime color="primary" />
            </IconButton>
          </Box>
        )}
      </Box>
    );

  return <DateStyled {...props} />;
}
export function DateConform({ label, ...props }: DateFieldProps) {
  return (
    <DateField
      label={label}
      name={props.field.name}
      error={Boolean(props.field.errors)}
      helperText={props.field.errors}
      defaultValue={getDefaultDate(props.field.initialValue as any)}
      key={props.field.key}
      aria-invalid={!props.field.valid || undefined}
      aria-describedby={!props.field.valid ? props.field.errorId : undefined}
      required={props.field.required}
      {...props}
    />
  );
}

export function getDefaultDate(str?: string | Date | null) {
  try {
    if (!str) return undefined;
    if (str instanceof Date) return str;
    const canFormat = toDate(str);
    if (canFormat && isDateValid(canFormat)) return canFormat; // si c'est au format "dd/MM/yyyy"
    const canFormat2 = toDate(str, FORMAT_ISO);
    if (canFormat2 && isDateValid(canFormat2)) return canFormat2; // si c'est au format "yyyy-MM-dd"
    const canFormatDateTime = toDate(str, FORMAT_DATE_TIME_ISO);
    if (canFormatDateTime && isDateValid(canFormatDateTime)) return canFormatDateTime; // si c'est au format date sérialiser ex: 2024-02-19T13:26:06.816Z
    const canFormatDateNaissance = toDate(str, FORMAT_DATE_PARTIEL); // si c'est au format "MM/yyyy"
    if (canFormatDateNaissance && isDateValid(canFormatDateNaissance))
      return canFormatDateNaissance;

    return undefined;
  } catch (e) {
    return undefined;
  }
}
