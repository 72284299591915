import { FieldMetadata, getInputProps } from "@conform-to/react";
import { FormControl, FormHelperText, TextField, TextFieldProps } from "@mui/material";
import { ErrorParagraph } from "components/ErrorParagraph";

export type InputProps = TextFieldProps;

export function Input(props: InputProps) {
  const errors = props?.helperText as string[];
  const width = props.fullWidth ? "100%" : "300px";
  return (
    <FormControl sx={{ width: width }}>
      <TextField
        variant="filled"
        size="small"
        margin="none"
        sx={{
          width: width,
          color: "primary.main",
          "input-label": {
            color: "primary.main",
          },
          input: {
            color: "primary.main",
          },
        }}
        {...props}
        helperText={null}
      />
      <FormHelperText error={props.error}>
        <>
          {errors?.map((text) => (
            <ErrorParagraph error={text} key={text} inputFieldError />
          ))}
        </>
      </FormHelperText>
    </FormControl>
  );
}

export type InputConformProps = TextFieldProps & {
  field: FieldMetadata<any, any, any>;
};

export function InputConform({ label, field, ...props }: InputConformProps) {
  return (
    <Input
      label={label}
      {...getInputProps(field, { type: "text" })}
      error={Boolean(field.errors)}
      helperText={field.errors}
      data-cy={field.name}
      {...props}
    />
  );
}
