import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {fetchJSON} from "../api/fetchJSON";
import {baseUrl} from "../api/baseUrl";
import {Role, saveUser, User} from "../api/auth";

export function OAuth2RedirectHandler() {
    const [isAuthenticated, setAuthenticated] = useState<AuthenticationStatus>(
        AuthenticationStatus.NONE
    );

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    useEffect(() => {
        // l'URL est construit avec le token d'acces
        const token: string | null = queryParameters.get("token");
        if (!token) {
            setAuthenticated(AuthenticationStatus.UNLOGGED);
        }
        else if (isAuthenticated === AuthenticationStatus.NONE) {
            localStorage.setItem("ACCESS_TOKEN", token);
            const url = new URL("/backend/v1/user", baseUrl);
            fetchJSON<User>(url).then((user) => {
                saveUser(user);

                if (user.role.toString() === Role.NONE.toString() || !user.societesAuthorisees) {
                    setAuthenticated(AuthenticationStatus.NO_AUTHORISATION);
                } else if (
                    user.role.toString() === Role.INTERMEDIAIRE.toString() &&
                    user.sansHabilitations
                ) {
                    setAuthenticated(AuthenticationStatus.NO_HABILITATION);
                } else {

                    setAuthenticated(AuthenticationStatus.AUTHENTICATED);
                }
            });
        }
    }, []); // Le tableau vide en second argument assure que useEffect ne s'exécute qu'une seule fois après le montage initial

    if (isAuthenticated === AuthenticationStatus.UNLOGGED) {
        return <Navigate to="/login"/>;
    }
    else if (isAuthenticated === AuthenticationStatus.AUTHENTICATED) {
        return <Navigate to="/"/>;
    }
    else if (isAuthenticated === AuthenticationStatus.NO_AUTHORISATION) {
        return <Navigate to="/error-authorisations-login"/>;
    }
    else if (isAuthenticated === AuthenticationStatus.NO_HABILITATION) {
        return <Navigate to="/error-habilitations-login"/>;
    }
    else {
        return (
            <div>
                <p>Loading</p>
            </div>
        );
    }
}

export default OAuth2RedirectHandler;

enum AuthenticationStatus {
    NONE,
    UNLOGGED,
    AUTHENTICATED,
    IN_ERROR,
    NO_AUTHORISATION,
    NO_HABILITATION
}
