import { FieldMetadata, getInputProps } from "@conform-to/react";
import { FormControl } from "@mui/base";
import {
  FormControlLabelProps,
  Checkbox as CheckboxMd,
  CheckboxProps as Props,
  FormHelperText,
  Typography,
} from "@mui/material";

export type CheckboxProps = Props &
  Pick<FormControlLabelProps, "label"> & {
    error?: boolean;
    helperText?: string;
  };

export function Checkbox({ label, error, helperText, ...props }: CheckboxProps) {
  return (
    <FormControl error={error}>
      <Typography component="label" color="primary" fontWeight="bold">
        <CheckboxMd checked={props.checked as boolean} {...props} />
        {label}
      </Typography>
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
}

export type CheckboxConformProps<T = any> = Omit<CheckboxProps, "error" | "helperText"> & {
  field: FieldMetadata<T | undefined>;
};

export function CheckboxConform({ field, ...props }: CheckboxConformProps) {
  return (
    <Checkbox
      {...getInputProps(field, { type: "checkbox" })}
      error={Boolean(field.errors)}
      helperText={field.errors ? String(field.errors) : undefined}
      data-cy={field.name}
      {...props}
    />
  );
}
