import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  AutoMode,
  GppMaybe,
  AlignVerticalCenter,
  PublishedWithChanges,
  Settings,
} from "@mui/icons-material";
import { TypeRecherche } from "pages/RechercheRIHorsParc/api";

export const profilPermissionsData = [
  {
    label: "Administration",
    icon: <Settings />,
    permissions: [
      {
        libellePermission: "Piste d'audit",
        codePermission: "ADMIN_PISTE_AUDIT",
        niveauRattachement: ["GPSA / AGIRA"],
        additionalParam: undefined,
        perimetre: [],
      },
      {
        libellePermission: "Mise à jour des actualités",
        codePermission: "ADMIN_ACTUALITES",
        niveauRattachement: ["GPSA / AGIRA"],
        additionalParam: undefined,
        perimetre: [],
      },
      {
        libellePermission: "Mise à jour de l'aide en ligne",
        codePermission: "ADMIN_AIDE",
        niveauRattachement: ["GPSA / AGIRA"],
        additionalParam: undefined,
        perimetre: [],
      },
      {
        libellePermission: "Configuration Organisations",
        codePermission: "ADMIN_ORGANISATION",
        niveauRattachement: ["GPSA / AGIRA"],
        additionalParam: undefined,
        perimetre: [],
      },
      {
        libellePermission: "Gestion des profils",
        codePermission: "ADMIN_PROFILS",
        niveauRattachement: ["GPSA / AGIRA"],
        additionalParam: undefined,
        perimetre: [],
      },
      {
        libellePermission: "Signature des certificats",
        codePermission: "ADMIN_CERTIFICATS_CLIENT",
        niveauRattachement: ["GPSA / AGIRA"],
        additionalParam: undefined,
        perimetre: [],
      },
    ],
  },
  {
    label: "Consultation et gestion des habilitations",
    icon: <AlignVerticalCenter />,
    permissions: [
      {
        libellePermission: "Mes délégataires",
        codePermission: "HABILITATION_GESTION",
        niveauRattachement: ["Assureur"],
        additionalParam: undefined,
        perimetre: ["G", "C", "S"],
      },
      {
        libellePermission: "Mes habilitations",
        codePermission: "HABILITATION_CONSULTATION",
        niveauRattachement: ["Intermédiaire"],
        perimetre: ["G", "C", "S"],
      },
      {
        libellePermission: "Consultation des habilitations",
        codePermission: "HABILITATION_CONSULTATION",
        niveauRattachement: ["GPSA / AGIRA"],
        perimetre: ["G", "C", "S"],
      },
    ],
  },
  {
    label: "Alimentation du RIE",
    icon: <PublishedWithChanges />,
    permissions: [
      {
        libellePermission: "Alimentation RI unitaire",
        codePermission: "ALIM_RI_UNITAIRE",
        niveauRattachement: ["Assureur", "Intermédiaire"],
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Alimentation RI par fichier",
        codePermission: "ALIM_RI_FICHIER",
        niveauRattachement: ["Assureur", "Intermédiaire"],
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Suivi des alimentations par fichier",
        codePermission: "ALIM_RI_SUIVI_FICHIER",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Transfert de masse",
        codePermission: "ALIM_RI_TRANSFERTS_MASSE",
        niveauRattachement: ["Assureur", "Intermédiaire"],
        perimetre: ["G"],
      },
    ],
  },
  {
    label: "Interrogation du RIE",
    icon: <GppMaybe />,
    permissions: [
      {
        libellePermission: "Interrogation RI hors parc particulier",
        codePermission: "INTERRO_RI_INFORMATION",
        niveauRattachement: ["Assureur", "Intermédiaire"],
        additionalParam: TypeRecherche.Particulier,
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Interrogation RI hors parc societe",
        codePermission: "INTERRO_RI_INFORMATION",
        niveauRattachement: ["Assureur", "Intermédiaire"],
        additionalParam: TypeRecherche.Societe,
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Interrogation RI par fichier",
        codePermission: "INTERRO_RI_CONFORMITE",
        niveauRattachement: ["Assureur", "Intermédiaire"],
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Suivi des interrogations par fichier",
        codePermission: "INTERRO_RI_SUIVI_FICHIER",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G", "C"],
      },
      {
        libellePermission: "Consultation mes relevés",
        codePermission: "INTERRO_RI_MES_RELEVES",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G", "C", "S"],
      },
      {
        libellePermission: "Gestion mes relevés",
        codePermission: "GESTION_RI_MES_RELEVES",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G", "C"],
      },
    ],
  },
  {
    label: "Suivi des traitements",
    icon: <AutoMode />,
    permissions: [
      {
        libellePermission: "Suivi des rejets",
        codePermission: "SUIVI_TRAITEMENT_REJETS",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G"],
      },
      {
        libellePermission: "Suivi des fichiers état",
        codePermission: "SUIVI_TRAITEMENT_FREQUENCES",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G"],
      },
      {
        libellePermission: "Suivi des transferts différés",
        codePermission: "SUIVI_TRAITEMENT_TRANSFERTS_DIFF",
        niveauRattachement: ["GPSA / AGIRA", "Assureur"],
        additionalParam: "",
        perimetre: [],
      },
      {
        libellePermission: "Gestion des alertes",
        codePermission: "SUIVI_TRAITEMENT_ALERTES",
        niveauRattachement: ["GPSA / AGIRA", "Assureur", "Intermédiaire"],
        perimetre: ["G"],
      },
    ],
  },
];
export type PermissionProfil = {
  libellePermission: string;
  codePermission: string;
  niveauRattachement: string[];
  additionalParam?: string;
  perimetre?: string[];
};
export type ProfilPermissionType = {
  label: string;
  icon: ReactJSXElement;
  permissions: PermissionProfil[];
};

function addTo(permissions: ProfilPermissionType["permissions"]) {
  return permissions.map((permission) => ({
    ...permission,
    to: `${permission.codePermission.toLocaleLowerCase().replaceAll("_", "-")}${
      permission.additionalParam ? `/${permission.additionalParam}` : ""
    }`,
  }));
}

export const menus = profilPermissionsData.map((p) => ({
  ...p,
  permissions: addTo(p.permissions),
}));

export type MenuType = (typeof menus)[0];

export const settings = menus[0];
