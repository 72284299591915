import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function ErrorAuthorisations({ isCallInLayout }: { isCallInLayout?: boolean }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 170px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ width: "30%", minWidth: "400px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            p: 2,
          }}
        >
          <img src="/logo.svg" alt="logo" height="102px" />

          <Typography color="error">
            Vous n'êtes pas autorisé à utiliser ce service. Veuillez contacter l'administrateur pour
            demander les accès.
          </Typography>
          {!isCallInLayout && (
            <Link to="/logout">
              <Button variant="contained">Retour</Button>
            </Link>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
